

























































































import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";
import moment from "moment";

type Reservation = {
  id: number;
  type_id: number;
  status: string;
  status_id: number;
  resource_name: string;
  building_complex_city: string;
  building_name: string;
  date_from: string;
  date_to: string;
  time_from: string;
  time_to: string;
};

@Component({
  filters: {
    date(value, format, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .format(format);
    }
  }
})
export default class MyReservationsPage extends Vue {
  filters = [
    { upcoming: true, value: this.$t("BOOKING.UPCOMING_RESERVATIONS") },
    { upcoming: false, value: this.$t("BOOKING.PAST_RESERVATIONS") }
  ];

  reservations: Reservation[] = [];

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("My reservations");
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get upcoming() {
    return this.$store.getters["booking/upcoming"];
  }

  set upcoming(filter: boolean) {
    this.$store.commit("booking/setUpcoming", filter);
  }

  get filteredReservations() {
    const unsorted = this.reservations.filter(
      _ => (this.upcoming ? [1, 2].includes(_.status_id) : [3, 4].includes(_.status_id))
      // this.filter
      //   ? moment().isAfter(moment(`${_.date_from} ${_.time_to}`))
      //   : moment().isSameOrBefore(moment(`${_.date_from} ${_.time_to}`))
    );

    return unsorted
      ?.slice()
      .sort((a, b) =>
        this.upcoming
          ? moment(`${a.date_from} ${a.time_from}`).unix() -
            moment(`${b.date_from} ${b.time_from}`).unix()
          : moment(`${b.date_from} ${b.time_to}`).unix() -
            moment(`${a.date_from} ${a.time_to}`).unix()
      );
  }

  beforeDestroy() {
    this.scrollTop();
  }

  created() {
    this.$store
      .dispatch("booking/fetchMyReservations")
      .then(({ data }) => (this.reservations = data.data));
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
